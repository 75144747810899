@import 'bootstrap/scss/functions';
@import 'shards-ui/src/scss/variables';
@import 'shards-ui/src/scss/mixins/breakpoints';

// Landing page styles.
.sharetask-landing-page{
  // Welcome section
  // Sets up the background cover image and the overlay gradient.
  .welcome {
    position: relative;
    height: 100vh;
    min-height: 700px;
    background: url('/images/welcome-cover.jpg') no-repeat center center fixed;
    background-size: cover;

    &:before {
      position: absolute;
      z-index: 0;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .8;
      background: theme-color("primary");
    }

    @include media-breakpoint-down(sm) {
      .inner-wrapper {
        text-align: center;
      }
    }

    @media (max-width: 400px) {
      .welcome-heading {
        font-size: 2.415rem;
      }
    }
  }

  // Custom section title styling.
  // Adds the small border at the bottom of each section title.
  .section-title {
    position: relative;
    &:after {
      content: '';
      width: 30px;
      height: 2px;
      background: theme-color("primary");
      position: absolute;
      left: 50%;
      margin-left: -15px;
      bottom: -20px;
    }
  }

  // Features
  .feature {
    .icon {
      border-radius: 50%;
      min-width: 65px;
      height: 65px;
      line-height: 65px;
      text-align: center;
      box-shadow: 0 2px 4px rgba(#212529, .1), 0 7px 14px rgba(#212529, .1);
      font-size: 27px;
    }
  }

  // Better separation between sections.
  .section {
    border-bottom: 1px solid lighten($blueish-grey, 54);
  }

  // Add a different tint to some sections.
  .section-invert {
    background: #f9fafc;
  }

  // Testimonials Section
  .testimonials .avatar {
    max-width: 80px;
    overflow: hidden;
  }
}
