@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import 'bootstrap/scss/functions';
@import 'shards-ui/src/scss/variables';

.task {
  h3 {
    letter-spacing: 0.05rem;
    &.timer {
      text-align: center
    }
  }

  pre {
    font-size: 1.25rem;
    line-height: 1.75rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: 'Inconsolata', $font-family-monospace;
  }

  button {
    font-family: $font-family-system-first;
  }

  .control {
    position: sticky;
    top: 1.75rem;
  }
}